$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.root {
  .item {
    display: flex;

    a {
      display: flex;
    }

    img {
      align-self: center;
      max-height: 41px !important;
      width: auto;
      max-width: 100%;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
      a {
        margin: 0.7rem 0;
      }
    }
  }
}
